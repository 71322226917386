import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  Suspense,
} from "react";
import firebase from "firebase";
import ReactTooltip from "react-tooltip";

import Loader from "react-loader-spinner";

import app from "../../firebase-config";

import { REQUEST_API, REACT_APP_MAP_KEY } from "../../common/constants";
import useWindowDimensions from "../../common/hooks/windowDimensionsHook";

import "./styles.css";

import FilterBar from "../../components/FilterBar";
import Network from "../../components/Network";

const GoogleMaps = React.lazy(() => import("../../components/GoogleMap"));
const HelpBar = React.lazy(() => import("../../components/HelpBar"));
const Header = React.lazy(() => import("../../components/Header"));
const InstituteInfoModal = React.lazy(() =>
  import("../../components/InstituteInfoModal")
);
const ContactsModal = React.lazy(() =>
  import("../../components/ContactsModal")
);
const AddInstituteModal = React.lazy(() =>
  import("../../components/AddInstituteModal")
);
const ReportModal = React.lazy(() => import("../../components/ReportModal"));
const MapModal = React.lazy(() => import("../../components/MapModal"));
const Search = React.lazy(() => import("../../components/Search"));
const ReportScreen = React.lazy(() => import("../../components/ReportScreen"));
const ContactsScreen = React.lazy(() =>
  import("../../components/ContactsScreen")
);

const Main = () => {
  const { width, height } = useWindowDimensions();

  const [isMap, setIsMap] = useState(true);
  const [bigIcon, setBigIcon] = useState("");
  const [newCenter, setNewCenter] = useState(null);
  const [institutes, setInstitutes] = useState(null);
  const [types, setTypes] = useState(null);
  const [reports, setReports] = useState(null);
  const [featureFlags, setFeatureFlags] = useState(null);
  const [activityType, setActivityType] = useState(null);
  const [employeesType, setEmployeesType] = useState(null);
  const [audienceType, setAudienceType] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    audience: [""],
    employees: [""],
    activity: [""],
    type: [""],
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [modalInstitute, setModalInstitute] = useState(null);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  const [isAddInstituteModalOpen, setIsAddInstituteModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [currentScreen, setCurrentScreen] = useState(null);
  const [selectedID, setSelectedID] = useState(-1);

  const [selectedNetwork, setSeleсtedNetwork] = useState(1);
  const [isNetworksLoaded, setIsNetworksLoaded] = useState(false);
  const [networkData, setNetworkData] = useState(null);

  const typedInstituteType = useMemo(
    () =>
      types && filter.search
        ? types.filter((instType) =>
            instType.name_ua.toLowerCase().includes(filter.search.toLowerCase())
          )
        : undefined,
    [filter.search, types]
  );

  const typedActivity = useMemo(
    () =>
      activityType && filter.search
        ? activityType.filter((activity) =>
            activity.name_ua.toLowerCase().includes(filter.search.toLowerCase())
          )
        : undefined,
    [filter.search, activityType]
  );

  // Initialization with firebase
  useEffect(() => {
    app.auth().onAuthStateChanged(() => {});
  }, []);

  useEffect(() => {
    if (width > 1000) {
      return setCurrentScreen(null);
    }
    return setCurrentScreen(0);
  }, [width]);

  useEffect(() => {
    getInstitutes();
    getTypes();
    getReports();
    getFeatureFlags();
    getActivityType();
    getEmployeesType();
    getAudienceType();
    getContacts();
    getNetwork();
  }, []);

  useEffect(() => {
    if (!isFilterOpen) {
      setFilteredList(
        institutes && institutes.length
          ? institutes
              .filter((institute) => institute.inst_type === 1)
              .filter(
                (institute) =>
                  institute["1-name"]
                    .toLowerCase()
                    .includes(filter.search.toLowerCase()) ||
                  typedInstituteType.some((typeName) =>
                    institute["2-type"].includes(typeName.type)
                  ) ||
                  typedActivity.some((typeName) =>
                    institute["14-1-activity_prioritization_major"].includes(
                      typeName.id
                    )
                  ) ||
                  typedActivity.some((typeName) =>
                    institute["14-2-activity_prioritization_minor"].includes(
                      typeName.id
                    )
                  ) ||
                  typedActivity.some((typeName) =>
                    institute["15-activity_other"].includes(typeName.id)
                  ) ||
                  institute["5-founded_at"].includes(filter.search) ||
                  institute["6-director_name"]
                    .toLowerCase()
                    .includes(filter.search.toLowerCase()) ||
                  institute["7-address_name"]
                    .toLowerCase()
                    .includes(filter.search.toLowerCase())
              )
              .filter((institute) =>
                filter.audience.some((type) =>
                  institute["13-target_audience"].includes(type)
                )
              )
              .filter((institute) =>
                filter.employees.some((type) =>
                  institute["12-employees"].includes(type)
                )
              )
              .filter((institute) =>
                filter.activity.some(
                  (type) =>
                    institute["14-1-activity_prioritization_major"].includes(
                      type
                    ) ||
                    institute["14-2-activity_prioritization_minor"].includes(
                      type
                    ) ||
                    institute["15-activity_other"].includes(type)
                )
              )
              .filter((institute) =>
                filter.type.some((type) => institute["2-type"].includes(type))
              )
          : []
      );
    }
  }, [
    filter,
    institutes,
    isFilterOpen,
    typedActivity,
    typedInstituteType,
    types,
  ]);

  const getInstitutes = () => {
    fetch(`${REQUEST_API}/institutes`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setInstitutes(responseData.data);
      });
  };

  const getTypes = async () => {
    fetch(`${REQUEST_API}/configs/orgType`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setTypes(responseData.data);
      });
  };

  const getReports = async () => {
    fetch(`${REQUEST_API}/reports`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setReports(responseData.data);
      });
  };

  const getFeatureFlags = async () => {
    fetch(`${REQUEST_API}/settings/featureFlags`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setFeatureFlags(responseData.data);
      });
  };

  const getActivityType = async () => {
    fetch(`${REQUEST_API}/configs/activityType`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setActivityType(responseData.data);
      });
  };

  const getEmployeesType = async () => {
    fetch(`${REQUEST_API}/configs/employeesType`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setEmployeesType(responseData.data);
      });
  };

  const getAudienceType = async () => {
    fetch(`${REQUEST_API}/configs/audienceType`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setAudienceType(responseData.data);
      });
  };

  const getContacts = async () => {
    fetch(`${REQUEST_API}/settings/contacts`)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setContacts(responseData.data);
      });
  };

  const getNetwork = async () => {
    fetch(`${REQUEST_API}/networks/1`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setNetworkData(response.data);
        setIsNetworksLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectNetwork = useCallback((id) => {
    setSeleсtedNetwork(id);
    setIsNetworksLoaded(false);

    fetch(`${REQUEST_API}/networks/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setNetworkData(response.data);
        setIsNetworksLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const switchToMap = useCallback(() => {
    if (featureFlags?.analytics) {
      firebase.analytics().logEvent("Перегляд карти");
    }
    setIsMap(true);
  }, [featureFlags]);

  const switchToNetWork = useCallback(() => {
    if (featureFlags?.analytics) {
      firebase.analytics().logEvent("Перегляд мереж");
    }
    setIsMap(false);
  }, [featureFlags]);

  const handleSetBigIcon = useCallback(() => setBigIcon(true), []);
  const handleSetSimpleIcon = useCallback(() => setBigIcon(false), []);
  const handleSetFilterOpen = useCallback(() => setIsFilterOpen(true), []);
  const handleSetFilterClose = useCallback(() => setIsFilterOpen(false), []);

  const openInstituteInfoModal = useCallback(
    (institute) => () => {
      setModalInstitute(null);

      if (featureFlags?.analytics) {
        firebase.analytics().logEvent("Перегляд паспорту існтитуції", {
          name: institute["1-name"],
        });
      }

      setModalInstitute(institute);
    },
    [featureFlags?.analytics]
  );

  const closeInstituteInfoModal = useCallback(() => {
    setModalInstitute(null);
  }, []);

  const openContactsModal = useCallback(() => {
    if (featureFlags?.analytics) {
      firebase.analytics().logEvent("Перегляд контактів");
    }

    setIsContactsModalOpen(true);
  }, [featureFlags?.analytics]);

  const closeContactsModal = useCallback(() => {
    setIsContactsModalOpen(false);
  }, []);

  const openAddInstituteModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (featureFlags?.analytics) {
        firebase.analytics().logEvent("Перегляд додавання інституції");
      }

      setIsAddInstituteModalOpen(true);
    },
    [featureFlags?.analytics]
  );

  const closeAddInstituteModal = useCallback(() => {
    setIsAddInstituteModalOpen(false);
  }, []);

  const openMapModal = useCallback(() => {
    setIsMapModalOpen(true);
  }, []);

  const closeMapModal = useCallback(() => {
    setIsMapModalOpen(false);
  }, []);

  const handleClickInstitution = useCallback(() => {
    setIsFilterOpen(false);
    setCurrentScreen(0);
  }, []);

  const handleClickMap = useCallback(() => {
    setIsFilterOpen(false);
    setCurrentScreen(1);
  }, []);

  const handleClickReport = useCallback(() => {
    if (featureFlags?.analytics) {
      firebase.analytics().logEvent("Перегляд списку звітів");
    }

    setIsFilterOpen(false);
    setCurrentScreen(2);
  }, [featureFlags]);

  const handleClickContacts = useCallback(() => {
    if (featureFlags?.analytics) {
      firebase.analytics().logEvent("Перегляд контактів");
    }

    setIsFilterOpen(false);
    setCurrentScreen(3);
  }, [featureFlags]);

  const openReportInstitute = useCallback(() => {
    setIsReportModalOpen(true);
  }, []);

  const closeReportInstitute = useCallback(() => {
    setIsReportModalOpen(false);
  }, []);

  return (
    <Suspense
      fallback={
        <div className="preloader-container">
          <Loader
            type="TailSpin"
            color="#F05929"
            height={100}
            width={100}
            timeout={5000}
          />
        </div>
      }
    >
      <Header
        reports={reports}
        featureFlags={featureFlags}
        openContactsModal={openContactsModal}
        openAddInstituteModal={openAddInstituteModal}
      />

      {currentScreen === null || currentScreen === 0 ? (
        <>
          {isFilterOpen ? (
            <FilterBar
              activity={activityType}
              audience={audienceType}
              employees={employeesType}
              setFilter={setFilter}
              closeFilter={handleSetFilterClose}
              filter={filter}
              types={types}
              currentScreen={currentScreen}
              height={height}
              width={width}
            />
          ) : (
            <HelpBar
              institutes={filteredList}
              types={types}
              featureFlags={featureFlags}
              filter={filter}
              employeesType={employeesType}
              activityType={activityType}
              allInstitutes={institutes}
              openModal={openInstituteInfoModal}
              setFilter={setFilter}
              setBigIcon={setBigIcon}
              setNewCenter={setNewCenter}
              setIsFilterOpen={handleSetFilterOpen}
              currentScreen={currentScreen}
              height={height}
              width={width}
            />
          )}
        </>
      ) : null}

      {currentScreen === null || currentScreen === 1 ? (
        <>
          {isFilterOpen && currentScreen === 1 ? (
            <FilterBar
              activity={activityType}
              audience={audienceType}
              employees={employeesType}
              setFilter={setFilter}
              closeFilter={handleSetFilterClose}
              filter={filter}
              types={types}
              currentScreen={currentScreen}
              height={height}
              width={width}
            />
          ) : (
            <>
              <Search
                isMap
                setIsFilterOpen={setIsFilterOpen}
                setFilter={setFilter}
              />
              {isMap ? (
                <GoogleMaps
                  bigIcon={bigIcon}
                  setBigIcon={handleSetBigIcon}
                  setSimpleIcon={handleSetSimpleIcon}
                  institutes={filteredList}
                  openModal={openInstituteInfoModal}
                  newCenter={newCenter}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div className="loading-container" />}
                  containerElement={
                    <div
                      style={width < 450 ? { maxHeight: height - 215 } : null}
                      className={`map-container ${
                        currentScreen === 1 && "mobile-map"
                      }`}
                    />
                  }
                  mapElement={<div className="map-element" />}
                />
              ) : (
                <Network
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                  setIsNetworksLoaded={setIsNetworksLoaded}
                  isNetworksLoaded={isNetworksLoaded}
                  data={networkData}
                />
              )}
            </>
          )}
        </>
      ) : null}

      {currentScreen === null || currentScreen === 1 ? (
        <div className={`switcher-container ${!isMap && "multiple-switcher"}`}>
          {!isMap && (
            <div className="network-switcher">
              <div
                className={`network-switch-option ${
                  selectedNetwork === 1 ? "chosen-switch" : ""
                }`}
                data-tip
                data-for="network-1"
                onClick={() => selectNetwork(1)}
              >
                <ReactTooltip
                  id="network-1"
                  place="bottom"
                  effect="solid"
                  className="tooltip"
                  backgroundColor="#fff"
                  textColor="#818a91"
                >
                  Мережа співпраці
                </ReactTooltip>
                <span className="desktop-network">Мережа</span> 1
              </div>
              <div
                className={`network-switch-option ${
                  selectedNetwork === 2 ? "chosen-switch" : ""
                }`}
                data-tip
                data-for="network-2"
                onClick={() => selectNetwork(2)}
              >
                <ReactTooltip
                  id="network-2"
                  place="bottom"
                  effect="solid"
                  className="tooltip"
                  backgroundColor="#fff"
                  textColor="#818a91"
                >
                  Зважена мережа
                </ReactTooltip>
                <span className="desktop-network">Мережа</span> 2
              </div>
              <div
                className={`network-switch-option ${
                  selectedNetwork === 3 ? "chosen-switch" : ""
                }`}
                data-tip
                data-for="network-3"
                onClick={() => selectNetwork(3)}
              >
                <ReactTooltip
                  id="network-3"
                  place="bottom"
                  effect="solid"
                  className="tooltip"
                  backgroundColor="#fff"
                  textColor="#818a91"
                >
                  Спільноти
                </ReactTooltip>
                <span className="desktop-network">Мережа</span> 3
              </div>
            </div>
          )}
          <div className="switcher">
            <div
              className={`switch-1 ${isMap ? "chosen-switch" : null}`}
              onClick={switchToMap}
            >
              Карта
            </div>
            <div
              className={`switch-2 ${!isMap ? "chosen-switch" : null}`}
              onClick={switchToNetWork}
            >
              Мережі
            </div>
          </div>
        </div>
      ) : null}

      <div className="navigation">
        <p
          className={`${currentScreen === 0 && "active-nav"}`}
          onClick={handleClickInstitution}
        >
          Інституції
        </p>
        <p
          className={`${currentScreen === 1 && "active-nav"}`}
          onClick={handleClickMap}
        >
          Карта
        </p>
        <p
          className={`${currentScreen === 2 && "active-nav"}`}
          onClick={handleClickReport}
        >
          Звіт
        </p>
        <p
          className={`${currentScreen === 3 && "active-nav"}`}
          onClick={handleClickContacts}
        >
          Контакти
        </p>
      </div>

      {currentScreen === 2 ? (
        <ReportScreen featureFlags={featureFlags} reports={reports} />
      ) : null}

      {currentScreen === 3 ? <ContactsScreen contacts={contacts} /> : null}

      <InstituteInfoModal
        institutes={institutes}
        institute={modalInstitute}
        closeModal={closeInstituteInfoModal}
        types={types}
        featureFlags={featureFlags}
        employeesType={employeesType}
        activityType={activityType}
        reOpenModal={openInstituteInfoModal}
        openReportInstituteModal={openReportInstitute}
      />

      <ContactsModal
        closeModal={closeContactsModal}
        isModalOpen={isContactsModalOpen}
        contacts={contacts}
      />

      <AddInstituteModal
        closeModal={closeAddInstituteModal}
        institutes={institutes}
        isModalOpen={isAddInstituteModalOpen}
        types={types}
        employeesType={employeesType}
        audienceType={audienceType}
        activityType={activityType}
        openMapModal={openMapModal}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
      />

      <MapModal
        closeModal={closeMapModal}
        isModalOpen={isMapModalOpen}
        setCoordinates={setCoordinates}
        coordinates={coordinates}
      />

      <ReportModal
        institute={modalInstitute}
        closeModal={closeReportInstitute}
        closeInstituteModal={closeInstituteInfoModal}
        modalIsOpen={isReportModalOpen}
      />
    </Suspense>
  );
};

export default Main;
