import React, { useCallback } from "react";

import { Form } from "react-bootstrap";

import { FaChevronLeft } from "react-icons/fa";

import { getAudienceIcon, getHalfOfArray } from "../../common/helpers";

import "./styles.css";

const FilterBar = ({
  activity,
  audience,
  closeFilter,
  currentScreen,
  employees,
  filter,
  height,
  setFilter,
  types,
  width,
}) => {
  const handleChangeType = (typeName, id) => {
    if (filter[typeName].includes(id)) {
      return setFilter((prevState) => ({
        ...prevState,
        [typeName]:
          prevState[typeName].length === 1
            ? [""]
            : prevState[typeName].filter((type) => type !== id),
      }));
    }
    return setFilter((prevState) => ({
      ...prevState,
      [typeName]:
        prevState[typeName][0] !== "" ? [...prevState[typeName], id] : [id],
    }));
  };

  const handleClear = useCallback(
    () =>
      setFilter({
        search: "",
        audience: [""],
        employees: [""],
        activity: [""],
        type: [""],
      }),
    [setFilter]
  );

  return (
    <div
      className={`filterbar-container ${
        (currentScreen === 0 || currentScreen === 1) && "width-100"
      }`}
      style={width < 450 ? { maxHeight: height - 210 } : null}
    >
      <div className="filter-nav">
        <FaChevronLeft onClick={closeFilter} />
        <p onClick={closeFilter}>Показати</p>
      </div>
      <div
        className="filter-content"
        style={width < 450 ? { maxHeight: height - 270 } : null}
      >
        <div className="filter-title">
          <p className="bold">Пошук по фільтру</p>
          <p className="clear" onClick={handleClear}>
            Очистити все
          </p>
        </div>
        <div className="filter-audience">
          <h3>Цільова аудиторія</h3>
          <div className="filter-audience-cards">
            {audience.map((type) => (
              <div
                className={`audience-card ${
                  filter.audience.includes(type.id) && "chosen"
                }`}
                onClick={() => handleChangeType("audience", type.id)}
              >
                {getAudienceIcon(type.id)}{" "}
                <p className="audience-name">{type.name_ua}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-employee">
          <h3>Кількість осіб в оранізації</h3>
          <div className="filter-employee-cards">
            {employees.map((type) => (
              <div
                className={`employee-card ${
                  filter.employees.includes(type.id) && "chosen"
                }`}
                onClick={() => handleChangeType("employees", type.id)}
              >
                <p className="employee-name">{type.name_ua}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-priority">
          <h3>Пріорітет діяльності</h3>
          <div className="filter-priority-cards">
            <div>
              {getHalfOfArray(activity, true).map((type, index) => (
                <Form.Group
                  className="mb-3"
                  controlId={`activityFirstCheckbox-${index}`}
                  onChange={() => handleChangeType("activity", type.id)}
                >
                  <Form.Check
                    type="checkbox"
                    label={type.name_ua}
                    checked={filter.activity.includes(type.id)}
                  />
                </Form.Group>
              ))}
            </div>
            <div>
              {getHalfOfArray(activity, false).map((type, index) => (
                <Form.Group
                  className="mb-3"
                  controlId={`activitySecondCheckbox-${index}`}
                  onChange={() => handleChangeType("activity", type.id)}
                >
                  <Form.Check
                    type="checkbox"
                    label={type.name_ua}
                    checked={filter.activity.includes(type.id)}
                  />
                </Form.Group>
              ))}
            </div>
          </div>
        </div>
        <div className="filter-type">
          <h3>Тип організації</h3>
          <div className="filter-type-cards">
            <div>
              {getHalfOfArray(types, true).map((type, index) => (
                <Form.Group
                  className="mb-3"
                  controlId={`typeFirstCheckbox-${index}`}
                  onChange={() => handleChangeType("type", type.type)}
                >
                  <Form.Check
                    type="checkbox"
                    label={type.name_ua}
                    checked={filter.type.includes(type.type)}
                  />
                </Form.Group>
              ))}
            </div>
            <div>
              {getHalfOfArray(types, false).map((type, index) => (
                <Form.Group
                  className="mb-3"
                  controlId={`typeSecondCheckbox-${index}`}
                  onChange={() => handleChangeType("type", type.type)}
                >
                  <Form.Check
                    type="checkbox"
                    label={type.name_ua}
                    checked={filter.type.includes(type.type)}
                  />
                </Form.Group>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
