import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyAuhof2C5DFdoKCYzc6PUBOdoTGuwcsMRY",
    authDomain: "instlandscape.firebaseapp.com",
    databaseURL: "https://instlandscape-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "instlandscape",
    storageBucket: "instlandscape.appspot.com",
    messagingSenderId: "511489372295",
    appId: "1:511489372295:web:05da6b4426ad4333674822",
    measurementId: "G-1QGDJYMWXR"
});

firebase.analytics().logEvent('notification_received');

export default app;
