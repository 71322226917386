export const REQUEST_API = "https://culturemap.isc.lviv.ua:8443";

export const LVIV_CENTER = {
  lat: 49.845,
  lng: 24.027,
};

export const REACT_APP_MAP_KEY = "AIzaSyC_iFh9F1o0sOkuUoGODjtSaCGmyxSN3WM";

export const SUCCESS_OPTIONS_STYLE = {
  position: "top-center",
  style: {
    backgroundColor: "#8BC34A",
    opacity: 0.95,
    fontSize: "14px",
  },
};

export const ERROR_OPTIONS_STYLE = {
  position: "top-center",
  style: {
    backgroundColor: "#FA3702",
    opacity: 0.85,
    fontSize: "14px",
  },
};
