import { Marker } from "react-google-maps";
import {
  FaFacebook,
  FaTelegram,
  FaViber,
  FaYoutube,
  FaLandmark,
  FaTheaterMasks,
  FaToriiGate,
  FaChessRook,
  FaGraduationCap,
  FaHotel,
  FaGopuram,
} from "react-icons/fa";
import { ImEarth, ImSoundcloud2, ImBooks, ImFilm } from "react-icons/im";
import { IoMusicalNotes, IoPeopleCircleSharp } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import {
  RiInstagramFill,
  RiMovie2Fill,
  RiGalleryFill,
  RiCommunityFill,
  RiBuilding2Fill,
} from "react-icons/ri";
import {
  GiBallerinaShoes,
  GiLyre,
  GiMagickTrick,
  GiTheaterCurtains,
} from "react-icons/gi";
import {
  MdMovieFilter,
  MdPalette,
  MdMovie,
  MdBubbleChart,
  MdLocationCity,
} from "react-icons/md";
import { BiBuildingHouse, BiNews } from "react-icons/bi";
import { SiDraugiemDotLv } from "react-icons/si";

import KidColored from "./icons/kid-colored.svg";
import TeenagerColored from "./icons/teenager-colored.svg";
import YoungColored from "./icons/young-colored.svg";
import AdultColored from "./icons/adult-colored.svg";
import PensionerColored from "./icons/pensioner-colored.svg";

import LandmarkIcon from "./icons/markers/landmark.svg";
import ToriiGateIcon from "./icons/markers/torii-gate.svg";
import ChessRockIcon from "./icons/markers/chess-rock.svg";
import TheaterMasksIcon from "./icons/markers/theater-masks.svg";
import MovieFilterIcon from "./icons/markers/movie-filter.svg";
import GraduationCapIcon from "./icons/markers/graduation-cap.svg";
import PalleteIcon from "./icons/markers/pallete.svg";
import MusicalNotesIcon from "./icons/markers/musical-notes.svg";
import BallerinaShoesIcon from "./icons/markers/ballerina-shoes.svg";
import LyreIcon from "./icons/markers/lyre.svg";
import TheaterCurtainsIcon from "./icons/markers/theater-curtains.svg";
import FilmIcon from "./icons/markers/film.svg";
import MovieIcon from "./icons/markers/movie.svg";
import Movie2FillIcon from "./icons/markers/movie-2-fill.svg";
import HotelIcon from "./icons/markers/hotel.svg";
import GalleryIcon from "./icons/markers/gallery.svg";
import BuildingIcon from "./icons/markers/building.svg";
import BooksIcon from "./icons/markers/books.svg";
import PeopleFillIcon from "./icons/markers/people-fill.svg";
import DraugiemdotlvIcon from "./icons/markers/draugiemdotlv.svg";
import CommunityFillIcon from "./icons/markers/community-fill.svg";
import Building2FillIcon from "./icons/markers/building-2-fill.svg";
import PeopleCircleSharpIcon from "./icons/markers/people-circle-sharp.svg";
import MagickTrickIcon from "./icons/markers/magick-trick.svg";
import LocationCityIcon from "./icons/markers/location-city.svg";
import NewsIcon from "./icons/markers/news.svg";
import GopuramIcon from "./icons/markers/gopuram.svg";
import BubbleChartIcon from "./icons/markers/bubble-chart.svg";
import DefaultMarkerIcon from "./images/marker.png";

export const getIcon = (network) => {
  switch (network) {
    case "facebook":
      return <FaFacebook />;
    case "instagram":
      return <RiInstagramFill />;
    case "soundcloud":
      return <ImSoundcloud2 />;
    case "telegram":
      return <FaTelegram />;
    case "viber":
      return <FaViber />;
    case "youtube":
      return <FaYoutube />;
    default:
      return <ImEarth />;
  }
};

export const getAudienceIcon = (id) => {
  switch (id) {
    case 0:
      return <img src={KidColored} alt="Kid" />;
    case 1:
      return <img src={TeenagerColored} alt="Teenager" />;
    case 2:
      return <img src={YoungColored} alt="Young" />;
    case 3:
      return <img src={AdultColored} alt="Adult" />;
    default:
      return <img src={PensionerColored} alt="Pensioner" />;
  }
};

export const getTypeIcon = (type) => {
  switch (type) {
    case "museum":
      return { icon: <FaLandmark />, color: "museum" };
    case "conservancy_area":
      return { icon: <FaToriiGate />, color: "conservancy_area" };
    case "library":
      return { icon: <ImBooks />, color: "library" };
    case "professional_theatre":
      return { icon: <FaChessRook />, color: "professional_theatre" };
    case "amateur_theater":
      return { icon: <FaTheaterMasks />, color: "amateur_theater" };
    case "acting_studio":
      return { icon: <MdMovieFilter />, color: "acting_studio" };
    case "university_department":
      return {
        icon: <FaGraduationCap />,
        color: "university_department",
      };
    case "art_school":
      return { icon: <MdPalette />, color: "art_school" };
    case "music_school":
      return { icon: <IoMusicalNotes />, color: "music_school" };
    case "dance_school":
      return { icon: <GiBallerinaShoes />, color: "dance_school" };
    case "philharmonic":
      return { icon: <GiLyre />, color: "philharmonic" };
    case "concert_hall_music_club":
      return { icon: <GiTheaterCurtains />, color: "concert_hall_music_club" };
    case "cinema":
      return { icon: <ImFilm />, color: "cinema" };
    case "film_club":
      return { icon: <MdMovie />, color: "film_club" };
    case "film_studio":
      return { icon: <RiMovie2Fill />, color: "film_studio" };
    case "art_center":
      return { icon: <FaHotel />, color: "art_center" };
    case "gallery":
      return { icon: <RiGalleryFill />, color: "gallery" };
    case "creative_professional_union":
      return {
        icon: <BiBuildingHouse />,
        color: "creative_professional_union",
      };
    case "NGO":
      return { icon: <BsPeopleFill />, color: "NGO" };
    case "perfomance_group":
      return { icon: <SiDraugiemDotLv />, color: "perfomance_group" };
    case "community_club":
      return {
        icon: <RiCommunityFill />,
        color: "community_club",
      };
    case "public_creative_center":
      return {
        icon: <RiBuilding2Fill />,
        color: "public_creative_center",
      };
    case "creative_studio":
      return {
        icon: <IoPeopleCircleSharp />,
        color: "creative_studio",
      };
    case "circus":
      return {
        icon: <GiMagickTrick />,
        color: "circus",
      };
    case "art_agency":
      return {
        icon: <MdLocationCity />,
        color: "art_agency",
      };
    case "event_agency":
      return {
        icon: <BiNews />,
        color: "event_agency",
      };
    case "informal_art_group":
      return {
        icon: <FaGopuram />,
        color: "informal_art_group",
      };

    default:
      return {
        icon: <MdBubbleChart />,
        color: "other",
      };
  }
};

export const formatActivities = (activities) =>
  activities && activities.length
    ? activities.map((type) => ({ value: type.id, label: type.name_ua }))
    : [];

export const formatTypes = (types) =>
  types && types.length
    ? types.map((type) => ({ value: type.type, label: type.name_ua }))
    : [];

export const formatValues = (values) =>
  values && values.length
    ? values.map((type) => ({ value: type.id, label: type.name_ua }))
    : [];

export const formatInstitutes = (values) =>
  values && values.length
    ? values.map((institute) => ({
        value: institute._id,
        label: institute["1-name"],
        typeId: institute.inst_type,
      }))
    : [];

export const getHalfOfArray = (fullArray, isFirstHalf) => {
  const half = isFirstHalf
    ? Math.ceil(fullArray?.length / 2)
    : Math.floor(fullArray?.length / 2);
  return isFirstHalf ? fullArray?.slice(0, half) : fullArray?.slice(-half);
};

export const transformRequestBody = (values, institutes, location) => ({
  "1-name": values["1-name"],
  "2-type": values["2-type"].map((type) => type.value).join(","),
  "3-legal_form": values["3-legal_form"],
  "4-subordination": values["4-subordination"],
  "5-founded_at": values["5-founded_at"],
  "7-address_lat": `${location?.geometry.location.lat}`,
  "7-address_lon": `${location?.geometry.location.lng}`,
  "7-address_name": values["7-address_name"],
  "8-contacts_tel": values["8-contacts_tel"].trim(),
  "9-contacts_email": values["9-contacts_email"],
  "10-contacts_web": values["10-contacts_web"],
  "11-1-social_facebook": values["11-1-social_facebook"],
  "11-2-social_instagram": values["11-2-social_instagram"],
  "11-3-social_tiktok": values["11-3-social_tiktok"],
  "11-4-social_telegram": values["11-4-social_telegram"],
  "11-5-social_viber": values["11-5-social_viber"],
  "11-6-social_youtube": values["11-6-social_youtube"],
  "11-7-social_twitter": values["11-7-social_twitter"],
  "11-8-social_soundcloud": values["11-8-social_soundcloud"],
  "11-9-social_other": values["11-9-social_other"],
  "12-employees": values["12-employees"].value,
  "13-target_audience":
    values["13-target_audience"]?.length &&
    values["13-target_audience"].join(","),
  "14-1-activity_prioritization_major": values[
    "14-1-activity_prioritization_major"
  ]
    .map((type) => type.value)
    .join(","),
  "14-2-activity_prioritization_minor": values[
    "14-2-activity_prioritization_minor"
  ]
    ?.map((type) => type.value)
    .join(","),
  "15-activity_other": values["15-activity_other"],
  "16-partners_lviv": values["16-partners_lviv"]
    .map((type) =>
      institutes.find((institute) => institute["1-name"] === type.label)
        ? type.value
        : `[${type.value}]`
    )
    .join(","),
  "17-partners_ukraine": values["17-partners_ukraine"]
    .map((type) =>
      institutes.find((institute) => institute["1-name"] === type.label)
        ? type.value
        : `[${type.value}]`
    )
    .join(","),
  "18-partners_world": values["18-partners_world"]
    .map((type) =>
      institutes.find((institute) => institute["1-name"] === type.label)
        ? type.value
        : `[${type.value}]`
    )
    .join(","),
  "19-projects": [
    {
      name: values["19-projects_1_name"],
      link: values["19-projects_1_link"],
    },
    {
      name: values["19-projects_2_name"],
      link: values["19-projects_2_link"],
    },
    {
      name: values["19-projects_3_name"],
      link: values["19-projects_3_link"],
    },
  ].filter((value) => value.name).length
    ? JSON.stringify(
        [
          {
            name: values["19-projects_1_name"],
            link: values["19-projects_1_link"],
          },
          {
            name: values["19-projects_2_name"],
            link: values["19-projects_2_link"],
          },
          {
            name: values["19-projects_3_name"],
            link: values["19-projects_3_link"],
          },
        ].filter((value) => value.name)
      )
    : undefined,
});

export const transformReportRequestBody = (values, institute) => ({
  institute_id: institute._id,
  contact: values["contact"],
  request_message: values["request_message"],
});

export const splitStringInObject = (object, key) =>
  object ? object[key].split(",") : null;

export const getSocial = (institute) => {
  const social = {};

  if (institute["10-contacts_web"].length) {
    social["вебсайт"] = institute["10-contacts_web"];
  }
  if (institute["11-1-social_facebook"].length) {
    social.facebook = institute["11-1-social_facebook"];
  }
  if (institute["11-2-social_instagram"].length) {
    social.instagram = institute["11-2-social_instagram"];
  }
  if (institute["11-3-social_tiktok"].length) {
    social.tiktok = institute["11-3-social_tiktok"];
  }
  if (institute["11-4-social_telegram"].length) {
    social.telegram = institute["11-4-social_telegram"];
  }
  if (institute["11-5-social_viber"].length) {
    social.viber = institute["11-5-social_viber"];
  }
  if (institute["11-6-social_youtube"].length) {
    social.youtube = institute["11-6-social_youtube"];
  }
  if (institute["11-7-social_twitter"].length) {
    social.twitter = institute["11-7-social_twitter"];
  }
  if (institute["11-8-social_soundcloud"].length) {
    social.soundcloud = institute["11-8-social_soundcloud"];
  }
  if (institute["11-9-social_other"].length) {
    social.other = institute["11-9-social_other"];
  }

  return social;
};

export const renderBadge = (
  types,
  type,
  filterable = false,
  setFilter,
  filter
) => {
  const instituteTypes = type.split(",");
  const typeNames =
    types && instituteTypes.length
      ? instituteTypes.map((instituteType) =>
          types.find((type) => type.type === instituteType)
        )
      : null;

  return typeNames && typeNames.length ? (
    <div className="badges-container">
      {typeNames.map((typeName, index) => (
        <div
          key={`badge-${index}`}
          className={`custom-badge ${getTypeIcon(typeName?.type).color} ${
            filterable && "filter-param"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            if (filterable) {
              setFilter((prevState) => ({
                ...prevState,
                type: filter.type.includes(typeName?.type)
                  ? [""]
                  : [typeName?.type],
              }));
            }
          }}
        >
          {getTypeIcon(typeName?.type).icon}
          {typeName?.name_ua}
        </div>
      ))}
    </div>
  ) : null;
};

export const findInstituteById = (institutes, id) =>
  institutes.find((institute) => institute._id === id);

export const renderMarker = (institute, index, bigIcon, openModal) => {
  let icon;
  let defaultSize;
  let bigSize;

  if (institute["1-name"]) {
    switch (institute["2-type"]?.split(",")[0]) {
      case "museum":
        icon = LandmarkIcon;
        break;
      case "conservancy_area":
        icon = ToriiGateIcon;
        break;
      case "professional_theatre":
        icon = ChessRockIcon;
        break;
      case "amateur_theater":
        icon = TheaterMasksIcon;
        break;
      case "acting_studio":
        icon = MovieFilterIcon;
        break;
      case "university_department":
        icon = GraduationCapIcon;
        break;
      case "art_school":
        icon = PalleteIcon;
        break;
      case "music_school":
        icon = MusicalNotesIcon;
        break;
      case "dance_school":
        icon = BallerinaShoesIcon;
        break;
      case "philharmonic":
        icon = LyreIcon;
        break;
      case "concert_hall_music_club":
        icon = TheaterCurtainsIcon;
        break;
      case "cinema":
        icon = FilmIcon;
        break;
      case "film_club":
        icon = MovieIcon;
        break;
      case "film_studio":
        icon = Movie2FillIcon;
        break;
      case "art_center":
        icon = HotelIcon;
        break;
      case "gallery":
        icon = GalleryIcon;
        break;
      case "creative_professional_union":
        icon = BuildingIcon;
        break;
      case "library":
        icon = BooksIcon;
        break;
      case "NGO":
        icon = PeopleFillIcon;
        break;
      case "perfomance_group":
        icon = DraugiemdotlvIcon;
        break;
      case "community_club":
        icon = CommunityFillIcon;
        break;
      case "public_creative_center":
        icon = Building2FillIcon;
        break;
      case "creative_studio":
        icon = PeopleCircleSharpIcon;
        break;
      case "circus":
        icon = MagickTrickIcon;
        break;
      case "art_agency":
        icon = LocationCityIcon;
        break;
      case "event_agency":
        icon = NewsIcon;
        break;
      case "informal_art_group":
        icon = GopuramIcon;
        break;
      default:
        icon = BubbleChartIcon;
        break;
    }

    defaultSize = new window.google.maps.Size(24, 34);
    bigSize = new window.google.maps.Size(70, 98);
  } else {
    icon = DefaultMarkerIcon;
    defaultSize = new window.google.maps.Size(22, 38);
    bigSize = new window.google.maps.Size(30, 52);
  }

  return (
    <Marker
      key={index}
      position={{
        lat: +institute["7-address_lat"],
        lng: +institute["7-address_lon"],
      }}
      // label={{ text: "test" }}
      icon={{
        url: icon,
        scaledSize: bigIcon === institute["1-name"] ? bigSize : defaultSize,
        labelOrigin: new window.google.maps.Point(35, 30),
      }}
      onClick={institute["1-name"] ? openModal(institute) : null}
    />
  );
};
