import React from "react";
import { Sigma } from "react-sigma";

import "./styles.css";

const Network = ({
  data,
  isNetworksLoaded,
  setIsNetworksLoaded,
  selectedID,
  setSelectedID,
}) => {
  const onClickNode = (e) => {
    const { id } = e.data.node;

    setIsNetworksLoaded(false);
    setSelectedID(selectedID === id ? -1 : id);
    setIsNetworksLoaded(true);
  };

  const getEdges = (id) => data.edges.filter((item) => item.target === id);

  const getNodes = (id) => {
    const edges = getEdges(id);
    const neededNodes = edges.map((item) => item.source);
    const nodes = data.nodes.filter(
      (item) => item.id === id || neededNodes.includes(item.id)
    );

    return nodes;
  };

  const getGraph = (id) =>
    id !== -1 ? { nodes: getNodes(id), edges: getEdges(id) } : data;

  return (
    <div className="network-container">
      {isNetworksLoaded ? (
        <Sigma
          renderer="canvas"
          onClickNode={onClickNode}
          graph={getGraph(selectedID)}
          style={{ paddingTop: "5%", height: "90%" }}
          settings={{
            drawEdges: true,
            clone: false,
            defaultLabelSize: 7,
            hoverFontStyle: "text-size: 12",
          }}
        ></Sigma>
      ) : null}
    </div>
  );
};

export default Network;
