import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SnackbarProvider from "react-simple-snackbar";

import Main from "./containers/main";

const App = () => (
  <SnackbarProvider>
    <Main />
  </SnackbarProvider>
);

export default App;
